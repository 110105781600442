import { Parallax } from "react-scroll-parallax";

import cn from "classnames";
import classes from "./styles.module.scss";

export default function ({ className, anim = "y0:x0:z0", image, floating = true, ...props }) {
  const [y, x, z] = anim.split(":");

  const cfg = {
    y2n: [0, -20],
    y1n: [0, -10],
    y0: [0, 0],
    y1: [0, 10],
    y2: [0, 20],

    x2n: [0, -10],
    x1n: [0, -5],
    x0: [0, 0],
    x1: [0, 5],
    x2: [0, 10],

    z2n: [1, 0.8],
    z1n: [1, 0.9],
    z0: [1, 1],
    z1: [1, 1.1],
    z2: [1, 1.2],
  };

  return (
    <Parallax
      translateY={cfg[y]}
      translateX={cfg[x]}
      scale={cfg[z]}
      className={cn(className, classes.ParallaxBackground)}
      data-floating={floating}
      {...props}
    >
      <img src={image} alt="" />
    </Parallax>
  );
}
